const { CITIBOX_API_HOST } = window.process.env;

export const queryString = obj =>
  obj &&
  Object.keys(obj)
    .filter(key => typeof obj[key] !== 'object' && typeof obj[key] !== 'undefined')
    .map(key => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');

export const queryObject = string => {
  let newString = string;
  if (newString.charAt(0) === '?') {
    newString = newString.slice(1);
  }
  const pairs = newString.split('&');
  const result = {};
  pairs.forEach(pair => {
    const newPair = pair.split('=');
    result[newPair[0]] = decodeURIComponent(newPair[1] || '');
  });
  return JSON.parse(JSON.stringify(result));
};

export const _parseJSON = response =>
  response.text().then(text => (text ? JSON.parse(text) : null));

export const getRequestUrl = (apiMethod, id, queryParams) => {
  let url = `https://${CITIBOX_API_HOST}/app_service/users/v1/${apiMethod}`;
  if (id) {
    url += `/${id}`;
  }
  url += queryParams ? `?${queryString(queryParams)}` : '';
  return url;
};

export const getRequestHeaders = headers => ({
  'Content-Type': 'application/x-www-form-urlencoded',
  accept: 'application/json',
  ...headers,
});

export const getRequestBody = body => queryString(body);

export const doRequest = async (url, method, headers, body) => {
  const newUrl = url;
  const newBody = body;

  const response = await fetch(newUrl, {
    method,
    headers,
    body: newBody,
  });
  return _parseJSON(response);
};
