import logout from '../services/logout';
import login from '../services/login';
import { getClient } from '../selectors';
import { navigate } from './app';
import { track } from '../services/analytics';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SENT = 'LOGIN_REQUEST_SENT';
export const LOGIN_REQUEST_SUCCEEDED = 'LOGIN_REQUEST_SUCCEEDED';
export const LOGIN_REQUEST_FAILED = 'LOGIN_REQUEST_FAILED';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_REQUEST_SENT = 'LOGOUT_REQUEST_SENT';
export const LOGOUT_REQUEST_SUCCEEDED = 'LOGOUT_REQUEST_SUCCEEDED';
export const LOGOUT_REQUEST_FAILED = 'LOGOUT_REQUEST_FAILED';

export const logoutRequestSent = () => ({
  type: LOGOUT_REQUEST_SENT,
});

export const logoutRequestSucceeded = () => ({
  type: LOGOUT_REQUEST_SUCCEEDED,
});

export const logoutRequestFailed = () => ({
  type: LOGOUT_REQUEST_FAILED,
});

export const loginRequestSent = () => ({
  type: LOGIN_REQUEST_SENT,
});

export const loginRequestSucceeded = payload => ({
  type: LOGIN_REQUEST_SUCCEEDED,
  payload,
});

export const loginRequestFailed = payload => ({
  type: LOGIN_REQUEST_FAILED,
  payload,
});

export const loginRequest = payload => async (dispatch, getState) => {
  const data = {
    username: payload.username,
    password: payload.password,
    client_id: getClient(getState()),
    scope: 'devices'
  };
  dispatch(loginRequestSent());
  track({ category: 'login', action: 'login-request' });
  try {
    const apiResponse = await login(data);
    await dispatch(loginRequestSucceeded(apiResponse));
    track({ category: 'login', action: 'login-success' });
  } catch (error) {
    dispatch(loginRequestFailed(error.message));
    track({ category: 'login', action: 'login-error', value: error.message });
  }
  return dispatch(navigate('/'));
};

export const logoutRequest = token => async (dispatch, getState) => {
  const data = { client_id: getClient(getState()), token };
  dispatch(logoutRequestSent());
  track({ category: 'logout', action: 'logout-request' });
  try {
    const apiResponse = await logout(data);
    await dispatch(logoutRequestSucceeded(apiResponse));
    track({ category: 'logout', action: 'logout-success' });
  } catch (error) {
    dispatch(logoutRequestFailed(error.message));
    track({ category: 'logout', action: 'logout-error', value: error.message });
  }
};
