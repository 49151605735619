import { LitElement, html, css } from 'lit-element';
import '@citibox/headings';
import { translate } from 'lit-translate';

class CitiboxViewLoginHeader extends LitElement {
  static get styles() {
    return css`
      .title {
        margin-bottom: 3em;
      }
      .subtitle {
        margin-bottom: 1.6em;
      }
    `;
  }

  render() {
    return html`
      <title-xxl class="title">${translate('login.welcome')}</title-xxl>
      <subtitle-xl class="subtitle">${translate('login.welcome-text-1')}</subtitle-xl>
      <subtitle-xl class="subtitle">${translate('login.welcome-text-2')}</subtitle-xl>
    `;
  }
}

window.customElements.define('citibox-view-login-header', CitiboxViewLoginHeader);
