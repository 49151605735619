/* eslint-disable camelcase */
import { getRequestUrl, getRequestHeaders, getRequestBody, doRequest } from './utils/request';
import { checkErrors } from './utils/errors';
import * as mock from './mock/login';

const { CITIBOX_API_HOST } = window.process.env;
const IS_MOCK = CITIBOX_API_HOST === 'citibox.mock';

const getLoginUrl = () => getRequestUrl('login');

const getLoginMethod = () => 'POST';

const getLoginHeaders = () => getRequestHeaders();

const getLoginBody = ({ username, password, client_id, scope }) =>
  getRequestBody({ username, password, client_id, scope });

const getLoginData = ({ username, password, client_id, scope }) => ({
  url: getLoginUrl(),
  method: getLoginMethod(),
  headers: getLoginHeaders(),
  body: IS_MOCK
    ? { username, password, client_id }
    : getLoginBody({ username, password, client_id, scope }),
});

const login = async ({ username, password, client_id, scope }) => {
  const { url, method, headers, body } = getLoginData({ username, password, client_id, scope});
  const loginResponse = IS_MOCK
    ? await mock.login(url, method, headers, body)
    : await doRequest(url, method, headers, body);

  return checkErrors(loginResponse);
};

export default login;
