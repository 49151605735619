import { LitElement, html } from 'lit-element';
import { connect } from 'pwa-helpers/connect-mixin';
import { installMediaQueryWatcher } from 'pwa-helpers/media-query';
import { installOfflineWatcher } from 'pwa-helpers/network';
import { installRouter } from 'pwa-helpers/router';
import { updateMetadata } from 'pwa-helpers/metadata';
import '@citibox/lava-wave';
import logo from '@citibox/logo';
import { translate, use, translateConfig, get } from 'lit-translate';

import { AppStyles } from './styles/app-styles';
import { store } from './store';
import {
  navigate,
  updateOffline,
  updateLayout,
  setCallbackUrl,
  setClient,
  setLocale,
} from './actions';
import { getLocale, getPage, isOffline, isSnackbarOpened, hasWideScreen } from './selectors';
import './components/snack-bar';
import './i18n';
import './components/citibox-view-login-header';
import { trackPage } from './services/analytics';

const { APP_DEFAULT_LANG } = window.process.env;

class CitiboxAccounts extends connect(store)(LitElement) {
  static get styles() {
    return [AppStyles];
  }

  static get properties() {
    return {
      callbackUrl: { type: String },
      client: { type: String },
      locale: { type: String },
      _page: { type: String },
      _snackbarOpened: { type: Boolean },
      _offline: { type: Boolean },
      _wideScreen: { type: Boolean },
    };
  }

  render() {
    return html`
      <div class="wrapper">
        <header class="header">
          <div class="main-title"><span class="header-logo">${logo}</span></div>
          <citibox-view-login-header class="header-content" ?active="${this._page === 'login'}">
          </citibox-view-login-header>
        </header>
        <lava-wave
          points="2"
          speed="30"
          delta="50"
          class="wave"
          percent="0.5"
          ?vertical="${this._wideScreen}"
          ?invert="${!this._wideScreen}"
        ></lava-wave>
        <main role="main" class="main-content">
          <citibox-view-redirect
            class="page"
            ?active="${this._page === 'redirect'}"
          ></citibox-view-redirect>
          <citibox-view-login
            class="page"
            ?active="${this._page === 'login'}"
            locale="${this.locale}"
          ></citibox-view-login>
          <citibox-view-logout
            class="page"
            ?active="${this._page === 'logout'}"
          ></citibox-view-logout>
          <citibox-view-404 class="page" ?active="${this._page === 'view404'}"></citibox-view-404>
        </main>
        <snack-bar ?active="${this._snackbarOpened}">
          ${this._offline ? translate('offline') : translate('online')}
        </snack-bar>
      </div>
    `;
  }

  async connectedCallback() {
    super.connectedCallback();
    store.dispatch(setCallbackUrl(this.callbackUrl));
    store.dispatch(setClient(this.client));
    store.dispatch(setLocale());
    await use(APP_DEFAULT_LANG, translateConfig);
    installMediaQueryWatcher('(min-width: 1025px)', matches =>
      store.dispatch(updateLayout(matches)),
    );
    installRouter(location =>
      store.dispatch(navigate(window.decodeURIComponent(location.pathname))),
    );
  }

  firstUpdated() {
    installOfflineWatcher(offline => store.dispatch(updateOffline(offline)));
  }

  /* eslint-disable no-unused-expressions */
  updated(changedProps) {
    super.updated(changedProps);
    if (changedProps.has('_page') && this._page) {
      const pageTitle = get(`${this._page}.title`);
      updateMetadata({
        title: pageTitle,
        description: pageTitle,
      });
      trackPage();

      switch (this._page) {
        case 'login':
          import('./components/citibox-view-login');
          break;
        case 'logout':
          import('./components/citibox-view-logout');
          break;
        case 'redirect':
          import('./components/citibox-view-redirect');
          break;
        default:
          this._page = 'view404';
          import('./components/citibox-view-404');
      }
    }
  }
  /* eslint-enable no-unused-expressions */

  stateChanged(state) {
    this.locale = getLocale(state);
    this._page = getPage(state);
    this._offline = isOffline(state);
    this._snackbarOpened = isSnackbarOpened(state);
    this._wideScreen = hasWideScreen(state);
  }
}

window.customElements.define('citibox-accounts', CitiboxAccounts);
