/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { FETCH_TIMEOUT, responseSuccess, responseError, getAuthorizationToken } from './utils';
import { _parseJSON } from '../utils/request';

const logoutSuccess = responseSuccess({}, 200);
const logoutNotAuthenticated = responseError({ code: 'not_authenticated' }, 401);

/* eslint-disable camelcase */
export const logout = async (url, method, headers, body) => {
  const token = getAuthorizationToken(headers);
  let response;

  if (token === '') {
    response = logoutNotAuthenticated();
  } else {
    response = logoutSuccess();
  }

  console.log('MOCK', url, { method, headers, body }, response);
  return new Promise(resolve => setTimeout(() => resolve(_parseJSON(response)), FETCH_TIMEOUT));
};
