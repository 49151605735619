const USER_BLOCKED = 'user_blocked';
const INVALID_DATA = 'invalid_data';
const INVALID_CREDENTIALS = 'invalid_credentials';
const INVALID_USER = 'invalid_user_type';
const UNAUTHORIZED = 'unauthorized';

export const ERROR_CODES = [
  USER_BLOCKED,
  INVALID_DATA,
  INVALID_CREDENTIALS,
  INVALID_USER,
  UNAUTHORIZED,
];

export const checkErrors = response => {
  if (response && response.code) {
    throw new Error(response.code);
  }
  return response;
};
