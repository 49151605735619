/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { FETCH_TIMEOUT, responseSuccess, responseError } from './utils';
import { _parseJSON } from '../utils/request';

const adminUser = '666666666';
const courierUser = '555555555';
const users = [adminUser, courierUser];
const userPassword = 'citibox1234';

const loginSuccess = (phone, password) =>
  responseSuccess(
    {
      access_token: `${phone}:${password}`,
      expires_in: 30 * 24 * 60 * 60 * 1000,
      token_type: 'Bearer',
      scope: '',
      refresh_token: `${password}:${phone}`,
    },
    200,
  );
const loginInvalidData = responseError({ code: 'invalid_data' }, 400);
const loginInvalidCredentials = responseError({ code: 'invalid_credentials' }, 401);
const loginInvalidUser = responseError({ code: 'invalid_user_type' }, 403);

export const login = (url, method, headers, body) => {
  const { username, password } = body;
  let response;

  if (!users.includes(username)) {
    response = loginInvalidData();
  } else if (userPassword !== password) {
    response = loginInvalidCredentials();
  } else if (courierUser === username) {
    response = loginInvalidUser();
  } else {
    response = loginSuccess(username, password)();
  }

  console.log('MOCK', url, { method, headers, body }, response);
  return new Promise(resolve => setTimeout(() => resolve(_parseJSON(response)), FETCH_TIMEOUT));
};
