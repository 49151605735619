import { env } from './env';
import { initGA } from './services/analytics';

const getQueryParams = (queryString = window.location.search.substring(1)) =>
  queryString
    .split('&')
    .map(str => {
      const [key, value] = str.split('=');
      return {
        [key]: decodeURI(value),
      };
    })
    .reduce((prev, curr) => Object.assign(prev, curr));

const {
  callback,
  callbackUrl, // for backwards compatibility
  client,
} = getQueryParams();
const app = document.querySelector('citibox-accounts');
app.setAttribute(
  'callbackUrl',
  callback ||
    callbackUrl ||
    (window.history.state && window.history.state.callback) ||
    env.CITIBOX_CALLBACK_URL,
);
app.setAttribute(
  'client',
  client || (window.history.state && window.history.state.client) || env.CITIBOX_CLIENT_ID,
);

initGA();
