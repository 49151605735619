import {
  LOGIN_REQUEST_SENT,
  LOGIN_REQUEST_SUCCEEDED,
  LOGIN_REQUEST_FAILED,
  LOGOUT_REQUEST_SENT,
  LOGOUT_REQUEST_SUCCEEDED,
  LOGOUT_REQUEST_FAILED,
} from '../actions';

import { getErrorMessage } from '../services/utils/error-messages';

const getInitialState = () => ({
  isLoading: false,
  isLoggedIn: false,
  token: '',
  refreshToken: '',
});

export const auth = (state = getInitialState(), action) => {
  switch (action.type) {
    case LOGIN_REQUEST_SENT:
    case LOGOUT_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        error: null,
        token: null,
        refreshToken: null,
      };
    case LOGIN_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: true,
        isLoggedIn: true,
        error: null,
        token: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
      };
    case LOGIN_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: getErrorMessage(action.payload),
        token: null,
        refreshToken: null,
      };
    case LOGOUT_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: null,
        token: null,
        refreshToken: null,
      };
    case LOGOUT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: getErrorMessage(action.payload),
        token: null,
        refreshToken: null,
      };
    default:
      return state;
  }
};
