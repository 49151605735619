import { SentryReporter } from '@citibox/error-reporter';

import { env } from '../env';

const shouldBeWarningMessages = [
  'You are currently using minified code outside of NODE_ENV === "production"',
  'TypeError: Failed to fetch dynamically imported module',
];

const ignoreErrors = [
  'TypeError: cancelled',
  'TypeError: cancelado',
  'TypeError: annulé',
  'TypeError: NetworkError when attempting to fetch resource',
];

const initErrorReporter = () => {
  const projectEnv = env.NODE_ENV === 'production' ? 'citibox-pro' : 'citibox-dev';

  if (env.DOMAIN) {
    const { APP_NAME, APP_VERSION, SENTRY_DSN } = env;

    SentryReporter.init({
      dsn: SENTRY_DSN,
      environment: projectEnv,
      release: `${APP_NAME}@${APP_VERSION}`,
      ignoreErrors,
      preprocess: {
        messages: {
          warning: shouldBeWarningMessages,
        },
      },
      /* tracesSampleRate: 0.25, */
    });
  }
};

initErrorReporter();

export default initErrorReporter;
