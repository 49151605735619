import { getCallbackUrl, getToken, getRefreshToken, isLoggedIn, isOffline } from '../selectors';

export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_OFFLINE = 'UPDATE_OFFLINE';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const UPDATE_LAYOUT = 'UPDATE_LAYOUT';
export const SET_CALLBACK_URL = 'SET_CALLBACK_URL';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_LOCALE = 'SET_LOCALE';
export const REDIRECT = 'REDIRECT';

const getRedirection = state => {
  let parsedUrl;
  try {
    parsedUrl = new URL(decodeURIComponent(getCallbackUrl(state)));
  } catch (e) {
    return false;
  }

  const fullCallbackUrl = `${parsedUrl.href}?token=${getToken(
    state,
  )}&refresh_token=${getRefreshToken(state)}`;

  if (isLoggedIn(state) && fullCallbackUrl && window.location.pathname !== '/logout') {
    window.location.href = fullCallbackUrl;
    return {
      url: '/redirect',
      data: { callbackUrl: fullCallbackUrl },
    };
  }
  return false;
};

const updatePage = (page, tail) => ({
  type: UPDATE_PAGE,
  page,
  tail,
});

const replaceState = (url, data) => () => {
  window.history.replaceState(data, '', url + window.location.search);
};

export const navigate = path => (dispatch, getState) => {
  const redirection = getRedirection(getState());
  if (redirection) {
    dispatch(replaceState(redirection.url, redirection.data));
  } else {
    const newPath = path === '/' ? 'login' : path;
    const tail = newPath.replace(/^\/|\/$/g, '').split('/');
    const page = tail.shift();
    dispatch(updatePage(page, tail));
  }
};

export const setCallbackUrl = callbackUrl => ({
  type: SET_CALLBACK_URL,
  callbackUrl,
});

export const setClient = client => dispatch => {
  dispatch({
    type: SET_CLIENT,
    client,
  });
};

export const setLocale = () => dispatch => {
  const { APP_DEFAULT_LANG, APP_SUPPORTED_LANGS } = window.process.env;
  const navigatorLang = (navigator.language || navigator.userLanguage).substring(0, 2);

  dispatch({
    type: SET_LOCALE,
    locale: APP_SUPPORTED_LANGS.includes(navigatorLang) ? navigatorLang : APP_DEFAULT_LANG,
  });
};

let snackbarTimer;

export const showSnackbar = () => dispatch => {
  dispatch({
    type: OPEN_SNACKBAR,
  });
  clearTimeout(snackbarTimer);
  snackbarTimer = setTimeout(() => dispatch({ type: CLOSE_SNACKBAR }), 3000);
};

export const updateOffline = offline => (dispatch, getState) => {
  // Show the snackbar, unless this is the first load of the page.
  if (isOffline(getState()) !== undefined) {
    dispatch(showSnackbar());
  }
  dispatch({
    type: UPDATE_OFFLINE,
    offline,
  });
};

export const updateLayout = wideScreen => ({
  type: UPDATE_LAYOUT,
  wideScreen,
});
