/* eslint-disable no-console */
export const FETCH_TIMEOUT = 200;
export const response = (body = '', init) => () => {
  console.log('BODY ==> ', body);
  return new Response(JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' },
    ...init,
  });
};

export const responseSuccess = (body, status = 200) => response(body, { status });
export const responseError = (body, status = 400) => response(body, { status });

export const getAuthorizationToken = headers => {
  const { authorization } = headers;
  if (!authorization) {
    return '';
  }
  return authorization.split(' ')[1] || '';
};
