import { css, unsafeCSS } from 'lit-element';
import { commonStyles, customProperties, typographies } from '@citibox/lava-typography';
import { citiboxColors, componentsColors, flavours } from '@citibox/flavours';

export const AppStyles = css`
  ${commonStyles}

  :host([unresolved]) {
    visibility: hidden;
  }

  :host {
    ${unsafeCSS(typographies.quicksandLato)}
    ${unsafeCSS(customProperties)};
    ${unsafeCSS(citiboxColors)};
    ${unsafeCSS(flavours.darkParty)};
    ${unsafeCSS(componentsColors)};
    display: block;
    background-color: rgb(var(--secondary-color));
    --app-max-width: 1440px;
    --app-margin: 40px;
    --app-header-height: 92px;
  }

  .header {
    top: 0;
    left: 0;
    padding: 0 22px;
    text-align: left;
  }

  .wave {
    height: 3em;
    margin-bottom: 1em;
    --lava-wave-color: rgb(var(--primary-color));
    --lava-wave-background: rgb(var(--secondary-color));
  }

  .header-logo {
    align-self: center;
    display: block;
    width: 87px;
    height: 21px;
    margin-left: 5px;
  }

  .header-logo svg {
    fill: rgb(var(--secondary-color));
    height: 100%;
  }

  .main-title {
    height: var(--app-header-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }

  .header {
    ${unsafeCSS(citiboxColors)};
    ${unsafeCSS(flavours.darkParty)};
    ${unsafeCSS(componentsColors)};
    background-color: rgb(var(--primary-color));
  }

  .header-content {
    padding: 1em 0;
  }

  /* Workaround for IE11 displaying <main> as inline */
  .main {
    display: block;
    background-color: rgb(var(--secondary-color));
  }

  .page {
    ${unsafeCSS(citiboxColors)};
    ${unsafeCSS(flavours.lightParty)};
    ${unsafeCSS(componentsColors)};
    background-color: rgb(var(--primary-color));
  }

  .page,
  .header-content {
    display: none;
  }

  .page[active],
  .header-content[active] {
    display: block;
  }

  snack-bar {
    background: rgb(var(--primary-color));
  }

  @media (min-width: 1025px) {
    :host {
      --app-header-height: 172px;
      background: linear-gradient(
        to right,
        rgb(var(--primary-color)) 50%,
        rgb(var(--secondary-color)) 50%
      );
    }

    .wrapper {
      box-sizing: border-box;
      display: flex;
      max-width: var(--app-max-width);
      width: 100%;
      margin: 0 auto;
      padding: 0 40px;
    }

    header,
    .wave,
    main {
      height: 100vh;
      min-height: 600px;
    }

    header {
      flex: 6 0 0;
    }
    .wave {
      flex: 2 0 0;
    }

    main {
      flex: 4 0 0;
    }

    header {
      display: flex;
      flex-direction: column;
    }

    .main-title {
      z-index: 1;
    }

    .wave {
      margin-bottom: 0;
    }

    main {
      display: flex;
      align-items: center;
      height: 100vh;
      min-height: 600px;
      margin-right: 8%;
    }

    .header-content {
      display: none;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      min-height: 600px;
      margin: 0;
      margin-top: calc(-1 * var(--app-header-height));
      padding: 0;
    }

    .header-content[active] {
      display: flex;
    }
  }
`;
