/* eslint-disable camelcase */
import { getRequestUrl, getRequestHeaders, getRequestBody, doRequest } from './utils/request';
import { checkErrors } from './utils/errors';
import * as mock from './mock/logout';

const { CITIBOX_API_HOST } = window.process.env;
const IS_MOCK = CITIBOX_API_HOST === 'citibox.mock';

const getLogoutUrl = () => getRequestUrl('logout');

const getLogoutMethod = () => 'POST';

const getLogoutHeaders = headers => getRequestHeaders(headers);

const getLogoutBody = ({ client_id, token }) => getRequestBody({ client_id, token });

const getLogoutData = ({ client_id, token }) => ({
  url: getLogoutUrl(),
  method: getLogoutMethod(),
  headers: getLogoutHeaders({ authorization: `Bearer ${token}` }),
  body: IS_MOCK ? { client_id } : getLogoutBody({ client_id }),
});

const logout = async ({ client_id, token }) => {
  const { url, method, headers, body } = getLogoutData({ client_id, token });
  const logoutResponse = IS_MOCK
    ? await mock.logout(url, method, headers, body)
    : await doRequest(url, method, headers, body);

  return checkErrors(logoutResponse);
};

export default logout;
